<!-- eslint-disable -->
<template>
  <loading :active="isLoading" :is-full-page="fullPage" />
  <div class="bg-login"></div>
  <div class="misc-wrapper misc-wrapper-sm">
    <div class="misc-content">
      <div class="container container-sm">
        <div class="box-conten-login">
          <h1 class="h2-login">SELAMAT DATANG</h1>
          <h1 class="h2-login">DI PORTAL SISTEM INFORMASI AKADEMIK</h1>
          <h1 class="h1-login">UNIVERSITAS MUHAMMADIYAH METRO LAMPUNG</h1>
        </div>
        <div class="justify-content-right">
          <div class="col-sm-12 col-md-5 padding-0">
            <div class="misc-header text-center bg-warning br-t-3">
              <img alt="" src="/img/icon.png" class="logo-icon margin-r-10">
              <h1 class="logo-title">LOGIN SIAKAD</h1>
            </div>
            <div class="misc-box misc-box-min pl-40 br-b-3">
              <div v-if="showError" id="error" class="alert alert-danger">
                Username dan Password tidak valid
              </div>
              <form role="form" @submit.prevent="userLogin">
                <div class="form-group">
                  <label for="exampleuser1">Username</label>
                  <div class="group-icon">
                    <input id="exampleuser1" type="text" placeholder="Username" class="form-control" v-model="form.username" required="">
                    <span class="icon-user text-muted icon-input"></span>
                  </div>
                </div>
                <div class="form-group">
                  <label for="exampleInputPassword1">Password</label>
                  <div class="group-icon">
                    <input id="exampleInputPassword1" type="password" placeholder="Password" class="form-control"  v-model="form.password">
                    <span class="icon-lock text-muted icon-input"></span>
                  </div>
                </div>
                <div class="clearfix">
                  <div class="float-left">
                    <div class="checkbox checkbox-primary margin-r-5">
                      <input id="checkbox2" type="checkbox" checked="">
                      <label for="checkbox2"> Remember Me </label>
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn btn-block btn-success btn-rounded box-shadow">Login Now</button>
                <label style="margin-top: 15px;font-size: 12px;color: #d62929;">Belum Memiliki Akun siakad!. Klik Registrasi Akun SSO</label>
                <div class="clearfix">
                  <div class="float-right">
                    <label style="font-weight: 600;"><router-link to="/media/registerakun" class="">Registrasi Akun SSO </router-link></label>
                  </div>
                </div>
              </form>
              <div class="text-center misc-footer">
                <p>Copyright &copy; 2019 Universitas Muhammadiyah Metro</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import { mapActions } from 'vuex'
export default {
  name: 'Admin',
  components: {
    Loading
  },
  data () {
    return {
      authRow: [],
      showError: false,
      loadAuth: false,
      loadProfil: false,
      fullPage: true,
      form: {
        username: '',
        password: ''
      },
      errorMsg: ''
    }
  },
  computed: {
    isLoading: function () {
      const load = !!((this.loadProfil || this.loadAuth))
      return load
    }
  },
  methods: {
    ...mapActions([
      'Login',
      'Logged'
    ]),
    userLogin () {
      this.loadAuth = true
      this.Login(JSON.stringify(this.form))
        .then((response) => {
          console.log('rolessss', response)
          this.loadAuth = false
          const roles = response.roles[0]
          if (roles === 'siakadmhs') { this.handleMahasiswa() }
          if (roles === 'adminbaak' || roles === 'pustik') { this.$router.push('/admin') }
        })
        .catch((error) => {
          this.showError = true
          this.errorMsg = (error.response) ? {} : {}
          this.loadAuth = false
        })
    },
    handleMahasiswa () {
      this.loadProfil = true
      this.Logged({ roles: 'mahasiswa' })
        .then((response) => {
          this.isLoading = false
          this.$router.push('/')
        })
        .catch((error) => {
          this.errorMsg = (error.response) ? {} : {}
          this.loadProfil = false
        })
    }
  }
}
</script>
<!-- eslint-disable -->
<style>
body {
  padding-top: 0px !important;
  position: inherit !important;
}
.bg-login {
  /* The image used */
  background-image: url("/img/world-map.png");

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  height: 100%;
  width: 100%;
}
</style>
