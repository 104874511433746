<!-- eslint-disable -->
<template>
  <loading v-model:active="isLoading" :is-full-page="fullPage" />
    <div v-show="alertMsg" class="alert bg-warning alert-dismissible" role="alert"> <strong>Informasi !</strong> Mohon lengkapi data ini </div>
            <form  role="form" method="post" action="">
              <div class="form-group">
                <label>Nama Lengkap</label>
                <input type="text" class="form-control form-control-noborder col-sm-12 col-md-11" v-model="sourceBiodata.nm_pd" :readonly="true">
              </div>
              <div class="form-group">
                <label>Tempat & Tanggal Lahir</label>
                <input type="text" class="form-control form-control-noborder col-sm-12 col-md-11" placeholder="Tempat kelahiran"  :value="sourceBiodata.tmpt_lahir + ' - ' + sourceBiodata.tgl_lahir" :readonly="true">
              </div>
              <div class="form-group">
                <label>N P M <small>(Nomor Pokok Mahasiswa)</small></label>
                <input type="text" v-model="sourceBiodata.nipd" class="form-control form-control-noborder col-sm-12 col-md-5" placeholder="" :readonly="true">
              </div>
              <div class="form-group">
                <label>Program Studi</label>
                <input type="text" v-model="sourceBiodata.prodi_nama" class="form-control form-control-noborder col-sm-12 col-md-5" placeholder="" :readonly="true">
              </div>
              <div class="form-group">
                <label>Nomor HP <small class="text-danger">*nomor harus Aktif</small></label>
                <input type="text" class="form-control input-xs col-sm-12 col-md-7" placeholder="" v-model="formValue.nomor_hp">
              </div>
              <div class="form-group">
                <label>Alamat Email <small class="text-danger">*email harus Aktif</small></label>
                <input type="text" class="form-control input-xs col-sm-12 col-md-11" placeholder="" v-model="formValue.email">
              </div>

              <div class="form-group">
                <label>USERNAME <small class="text-danger">*username login harus NPM</small></label>
                <input type="text" class="form-control input-xs col-sm-12 col-md-11" placeholder="" v-model="formValue.username" :readonly="true">
              </div>
              <div class="form-group">
                <label>PASSWORD <small class="text-danger">*password login</small></label>
                <input type="text" class="form-control input-xs col-sm-12 col-md-11" placeholder="" v-model="formValue.password">
              </div>
              <button type="button" @click="$emit('back-menu')" class="btn btn-warning btn-rounded box-shadow">Kembali</button>
              <button type="button" @click="$emit('button-submit', formValue)" class="btn btn-success btn-rounded box-shadow">Proses Akun</button> &nbsp;
              
            </form>
</template>

<script>
/* global $ */
import Loading from 'vue-loading-overlay'
import { getAgama, getWilayah } from '@/services/other.service'
import { getMhs, putMhs } from '@/services/mahasiswa.service'
export default {
  name: 'EditBiodata',
  components: {
    Loading
  },
  props: {
    sourceBiodata: {
      type: Object,
      default () {
        return { }
      }
    }
  },
  emits: ['back-menu', 'button-submit'],
  data (props) {
    const nipd = props.sourceBiodata.nipd
    const nomorHp = props.sourceBiodata.telepon_seluler
    const tglLahir = props.sourceBiodata.tgl_lahir
    return {
      formValue: {
        username: nipd,
        tanggal_lahir: tglLahir,
        password: '',
        email: '',
        nomor_hp: nomorHp
      },
      rowMhs: {},
      rowsAgama: [],
      rowsWilayah: [],
      valName: {},
      setKelamin: [{ id: 'P', nama: 'Perempuan' }, { id: 'L', nama: 'Laki-Laku' }],
      searchWill: '',
      loadData: false,
      loadSubmit: false,
      loadWilayah: false,
      loadAgama: false,
      fullPage: true,
      errorMsg: ''
    }
  },
  computed: {
    isLoading: function () {
      const load = !!((this.loadData || this.loadSubmit || this.loadWilayah || this.loadAgama))
      return load
    },
    alertMsg: function () {
      const load = (this.valName.updated_at === null)
      return load
    }
  },
  created () {
    this.handleAgama()
  },
  mounted () {
    const vm = this
    $('.tglLhr').datepicker({
      clearBtn: true,
      autoclose: true,
      format: 'dd/mm/yyyy'
    }).on('changeDate', function (ev) {
      vm.valName.tanggal_lahir = ev.target.value
    })
    $('.wilayah').select2({
      ajax: {
        url: vm.apiurl + '/wilayah/all',
        headers: { 'X-API-KEY': vm.XApiKey, Authorization: vm.token },
        dataType: 'json',
        data: function (params) {
          var query = {
            nama: params.term
          }
          return query
        },
        processResults: function (data) {
          const records = data.records
          var returnRow = []
          $.each(records, function (key, value) {
            returnRow.push({
              id: value.id,
              text: value.nama
            })
          })
          return {
            results: returnRow
          }
        }
      },
      theme: 'bootstrap4',
      width: $(this).data('width') ? $(this).data('width') : $(this).hasClass('w-100') ? '100%' : 'style',
      placeholder: $(this).data('placeholder'),
      allowClear: Boolean($(this).data('allow-clear'))
    }).on('select2:select', function (e) {
      vm.valName.id_wilayah = e.params.data.id
    })
  },
  methods: {
    async handleAgama () {
      this.loadAgama = true
      getAgama({})
        .then(response => {
          if (response.error_code === 200) {
            this.rowsAgama = response.records
          }
          this.loadAgama = false
        })
        .catch((error) => {
          this.errorMsg = (error.response) ? {} : {}
          this.loadAgama = false
        })
    },
    async handleWilayah () {
      this.loadWilayah = true
      getWilayah({ id: this.valName.id_wilayah })
        .then(response => {
          if (response.error_code === 200) {
            this.rowsWilayah = response.records
          }
          this.loadWilayah = false
        })
        .catch((error) => {
          this.errorMsg = (error.response) ? {} : {}
          this.loadWilayah = false
        })
    },
    async handleBiodata () {
      this.loadData = true
      getMhs({ nipd: this.authRow.username })
        .then((response) => {
          this.isLoading = false
          if (response.status !== 200) {
            this.showError = true
          } else {
            this.loadData = false
            this.valName = response.records
            $('.tglLhr').datepicker('setDate', new Date(this.valName.tanggal_lahir))
            this.handleWilayah()
          }
        })
        .catch((error) => {
          this.errorMsg = (error.response) ? {} : {}
          this.loadData = false
        })
    },
    async submit () {
      this.loadSubmit = true
      putMhs(this.valName)
        .then(response => {
          if (response.error_code === 200) {
            $.toast({
              heading: 'Good job!',
              text: 'Data akun spada berhasil dibuat',
              position: 'top-right',
              loaderBg: '#fff',
              icon: 'success',
              hideAfter: 3000,
              stack: 1
            })
            this.handleBiodata()
            this.loadSubmit = false
          } else {
            $.toast({
              heading: 'Error !',
              text: response.messages,
              position: 'top-right',
              loaderBg: '#fff',
              icon: 'error',
              hideAfter: 10000,
              stack: 1
            })
            this.loadSubmit = false
          }
        }).catch(error => {
          this.loadSubmit = false
          this.errorMsg = (error.response) ? {} : {}
        })
    }
  }
}
</script>
