<!-- eslint-disable -->
<template>
  <loading :active="isLoading" :is-full-page="fullPage" />
  <div class="bg-login"></div>
  <div class="misc-wrapper misc-wrapper-sm">
    <div class="misc-content">
      <div class="container container-sm">
        <div class="box-conten-login">
          <h1 class="h2-login">SELAMAT DATANG</h1>
          <h1 class="h2-login">DI PORTAL SISTEM INFORMASI AKADEMIK</h1>
          <h1 class="h1-login">UNIVERSITAS MUHAMMADIYAH METRO LAMPUNG</h1>
        </div>
        <div class="justify-content-right">
          <div class="col-sm-12 col-md-5 padding-0">
            <div class="misc-header text-center bg-warning br-t-3">
              <img alt="" src="/img/icon.png" class="logo-icon margin-r-10">
              <h1 class="logo-title" style="font-weight:600">DAFTAR AKUN SSO</h1>
            </div>
            <div class="misc-box misc-box-min br-b-3">
              <div v-if="showError" id="error" class="alert alert-danger">
                {{ errorMsg }}
              </div>
              <template v-if="showPageSearch">
                <form role="form" @submit.prevent="searchMhs">
                  <div class="form-group">
                    <label for="exampleuser1">Nomor Pokok Mahasiswa (N P M )</label>
                    <div class="group-icon">
                      <input id="exampleuser1" type="text" placeholder="N P M" class="form-control" v-model="form.npm" required="">
                      <span class="icon-user text-muted icon-input"></span>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="exampleInputPassword1">Tanggal Lahir</label>
                    <div class="input-group">
                      <input type="text" class="tanggal_lahir date" v-model="form.tanggal_lahir" />
                      <span class="input-group-addon"><i class="glyphicon glyphicon-calendar fa fa-calendar"></i></span>
                    </div>
                  </div>
                  <button type="submit" class="btn btn-block btn-success btn-rounded box-shadow">Cari Data Saya</button>
                  <label style="margin-top: 15px;font-size: 12px;color: #d62929;">Sudah Memiliki Akun siakad!. Klik Halaman Login</label>
                  <div class="clearfix">
                  
                    <div class="float-right">
                      <label style="font-weight: 600;"><router-link to="/media/login" class="">Halaman Login </router-link></label>
                    </div>
                  </div>
                </form>
              </template>
              <template v-if="showPageBiodata">
                <form-register-akun :source-biodata="sourceData" @back-menu="handleFormSearch" @button-submit="handleSubmit"></form-register-akun>
              </template>
              
              <div class="text-center misc-footer">
                <p>Copyright &copy; 2019 Universitas Muhammadiyah Metro</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* global $ */
import Loading from 'vue-loading-overlay'
import FormRegisterAkun from '@/components/bidoata/FormRegisterAkun'
import { defineComponent, ref } from 'vue'
import { mapActions } from 'vuex'
export default defineComponent({
  name: 'Admin',
  components: {
    Loading,
    FormRegisterAkun
  },
  data () {
    return {
      showPageSearch: true,
      showPageBiodata: false,
      sourceData: ref({}),
      sourceToken: ref(),
      showError: false,
      loadAuth: false,
      loadProfil: false,
      fullPage: true,
      form: {
        npm: '',
        tanggal_lahir: ''
      },
      errorMsg: 'Npm dan Tanggal Lahir tidak valid'
    }
  },
  computed: {
    isLoading: function () {
      const load = !!((this.loadProfil || this.loadAuth))
      return load
    }
  },
  mounted () {
    const vm = this
    $('.tanggal_lahir').datepicker({
      clearBtn: true,
      autoclose: true,
      format: 'dd/mm/yyyy'
    }).on('changeDate', function (ev) {
      vm.form.tanggal_lahir = ev.target.value
    })
  },
  methods: {
    ...mapActions([
      'SearchNpm',
      'RegistrasiAkun'
    ]),
    handleFormSearch () {
      this.showPageSearch = true
      this.showPageBiodata = false
    },
    handleSubmit (source) {
      const params = {
        npm: source.username,
        tanggal_lahir: source.tanggal_lahir,
        password: source.password,
        email: source.email,
        nomor_hp: source.nomor_hp,
        token: this.sourceToken
      }
      console.log('params', params)
      this.RegistrasiAkun(JSON.stringify(params))
        .then((response) => {
          this.loadAuth = false
          this.showError = false
          $.toast({
            heading: 'Good job!',
            text: 'Registrasi akun siakad berhasil',
            position: 'top-right',
            loaderBg: '#fff',
            icon: 'success',
            hideAfter: 3000,
            stack: 1
          })
          setTimeout(() => {
            this.$router.push('/login')
          }, 1000)
        })
        .catch((error) => {
          $.toast({
            heading: 'INFO !',
            text: `${error}`,
            position: 'top-right',
            loaderBg: '#fff',
            icon: 'error',
            hideAfter: 3000,
            stack: 1
          })
          this.loadAuth = false
          this.showError = false
        })
    },
    searchMhs () {
      this.loadAuth = true
      this.SearchNpm(JSON.stringify(this.form))
        .then((response) => {
          this.showPageSearch = false
          this.showPageBiodata = true
          this.sourceData = response.data
          this.sourceToken = response.token
          this.loadAuth = false
          this.showError = false
        })
        .catch((error) => {
          $.toast({
            heading: 'INFO !',
            text: `${error}`,
            position: 'top-right',
            loaderBg: '#fff',
            icon: 'error',
            hideAfter: 3000,
            stack: 1
          })
          this.loadAuth = false
          this.showError = false
        })
    }
  }
})
</script>
<!-- eslint-disable -->
<style>
body {
  padding-top: 0px !important;
  position: inherit !important;
}
.bg-login {
  /* The image used */
  background-image: url("/img/world-map.png");

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  height: 100%;
  width: 100%;
}
</style>
